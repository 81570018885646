import {
  useReducer,
  // useEffect
} from "react";

import { chatFilterOptions } from "../utils/common";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  chats: null,
  isChatsLoaded: false,
  isChatsLoading: false,
  activeConversation: -1,
  previousActiveConversation: -1,
  contacts: null,
  conversations: {},
  messageSending: false,
  reloadChats: false,
  freshChat: [], // this to keep track for the new chat
  networkFilters: {},
  discoverNetworkFilter: {},
  isDiscoverNetworkFilterInProgress: false,
  currentSearchMessageItem: null, // needs to hold the record for lazy loading and updating UI
  unreadMessagesCount: 0,
  triggerReloadUnreadCount: false, //this will help to re-call custom hook again
  broadcastData: null,
  attachment: false,
  groupTags: [],
  currentGroupModalId: -1, // this is for the case when you need to add new group member to already created group
  isGroupCreating: false,
  groupCreateRequestData: null, // Data to update / create group over socket
  favoriteUsersCount: null,
  chatFilterTab: chatFilterOptions[0],
  socketConnectionStatus: false, // Tracks if socket conversation channel is subscribed to
  socketReactionsConnectionStatus: false, // Tracks if socket conversation reactions channel is subscribed to
  broadcastMessage: null, // Holds the latest socket broadcast message
  broadcastMessageReactions: null, // Holds the latest socket reactions broadcast message
  replyChatData: {
    message_id: null,
    user_id: null,
    user_name: "",
    message: "",
    file_name: "",
  },
};

const useChat = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  // useEffect(() => {
  //   console.log("useChat", state);
  // }, [state]);

  /**
   * set chats data
   * @param {*} chats
   */
  const setChats = (chats) => {
    setState({
      chats,
    });
  };

  /**
   * Set socket conversation connection status
   * @param {Boolean} socketConnectionStatus
   */
  const setSocketConnectionStatus = (socketConnectionStatus) => {
    setState({
      socketConnectionStatus,
      ...(!socketConnectionStatus && {
        broadcastMessage: null,
        conversationChannelSpeakMethod: null,
      }),
    });
  };

  /**
   * Set socket conversation reactions connection status
   * @param {Boolean} socketReactionsConnectionStatus
   */
  const setSocketReactionsConnectionStatus = (
    socketReactionsConnectionStatus = false
  ) => {
    setState({
      socketReactionsConnectionStatus,
    });
  };

  /**
   * Set socket reactions broadcast message
   * @param {Object} broadcastMessage
   */
  const setConversationReactionsChannelBroadcast = (
    broadcastMessageReactions = null
  ) => {
    setState({
      broadcastMessageReactions,
    });
  };

  /**
   * Set socket broadcast message
   * @param {Object} broadcastMessage
   */
  const setConversationChannelBroadcast = (broadcastMessage = null) => {
    setState({
      broadcastMessage,
    });
  };

  /**
   * Reorder chats without need to call any external API e.g when we text someone in middle of list
   * @param {*} conversation_id
   * @returns
   */
  const reorderChats = (conversation_id) => {
    if (state.chats.length <= 1) return;
    if (state.chats[0].conversation_id === conversation_id) return;
    const chat = state.chats.find(
      (chat) => chat.conversation_id === conversation_id
    );
    const chats = state.chats.filter(
      (chat) => chat.conversation_id !== conversation_id
    );
    setChats([chat, ...chats]);
  };

  /**
   * Update the latest message inside the chats preview
   * @param {*} latest_message
   * @param {*} conversation_id
   * @returns
   */
  const updateLatestMessage = (latest_message, conversation_id) => {
    try {
      if (state.chats.length < 1) return;
      const chats = state.chats.map((chat) =>
        chat.conversation_id === conversation_id
          ? {
              ...chat,
              latest_message,
              time: new Date().toISOString(),
              conversation_type: "simple",
            }
          : { ...chat }
      );

      if (state.chats[0].conversation_id !== conversation_id) {
        const chat = chats.find(
          (chat) => chat.conversation_id === conversation_id
        );
        const items = chats.filter(
          (chat) => chat.conversation_id !== conversation_id
        );

        setChats([chat, ...items]);
        return;
      }
      setChats(chats);
    } catch (error) {
      console.log(`error ${error.message}`);
    }
  };

  /**
   * Get single chat preview
   * @param {*} id
   * @returns
   */
  const getChatPreviewByReceiverId = (id) => {
    if (!state?.chats) return null;
    return state.chats.find((chat) => chat.receiver_id === id);
  };

  /**
   * Update chat unread count
   * @param {*} conversation_id
   * @param {*} count
   */
  const updateChatsUnreadCount = (conversation_id, count = 0) => {
    try {
      const chats = state.chats.map((chat) =>
        chat.conversation_id === conversation_id
          ? { ...chat, count }
          : { ...chat }
      );
      setState({ chats });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Set is Chat loaded
   * @param {*} isChatsLoaded
   */
  const setIsChatsLoaded = (isChatsLoaded) => {
    setState({
      isChatsLoaded,
    });
  };

  /**
   * Setter group create / update request data
   * @param {*} groupCreateRequestData data to send over socket speak method
   */
  const setGroupCreateRequestData = (groupCreateRequestData) => {
    setState({
      groupCreateRequestData,
    });
  };

  /**
   * Set is Chats Loading
   * @param {*} isChatsLoading
   */
  const setIsChatsLoading = (isChatsLoading) => {
    setState({
      isChatsLoading,
    });
  };

  const getChatsIdsOnly = () => {
    if (!state.chats) return [];
    return state.chats.map((item) => item.conversation_id);
  };

  /**
   * Set the current/active conversation Id
   * @param {*} activeConversation
   */
  const setCurrentConversationId = (activeConversation) => {
    setState({
      previousActiveConversation: state.activeConversation,
      activeConversation,
    });
  };

  /**
   * Store conversation record
   * @param {*} conversation_id
   * @param {*} conversation
   */
  const setConversationInformation = (
    conversation_id,
    conversation,
    replace = false
  ) => {
    try {
      const _conversation = getConversationByConversationID(conversation_id);
      if (!_conversation || _conversation?.current_page === 1 || replace) {
        setState({
          conversations: {
            ...state.conversations,
            [conversation_id]: {
              ...conversation,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Update conversation details
   * @param {*} conversation_id
   * @param {*} messages
   * @param {*} pagination
   */
  const updateConversationInformation = (
    conversation_id,
    messages,
    pagination
  ) => {
    const conversation = getConversationByConversationID(conversation_id);
    const updatedConversation = {
      ...conversation,
      messages: [...messages, ...conversation.messages],
      ...pagination,
    };
    setState({
      conversations: {
        ...state.conversations,
        [conversation_id]: {
          ...updatedConversation,
        },
      },
    });
  };

  /**
   * Get conversation by conversation id
   * @param {*} conversation_id
   * @returns
   */
  const getConversationByConversationID = (conversation_id) => {
    if (Object.keys(state.conversations).length === 0) {
      return null;
    }
    return (
      state.conversations[conversation_id ?? state.activeConversation] || null
    );
  };

  /**
   * Remove Conversation Information
   * @param {} conversation_id
   */
  const removeConversationByConversationID = (conversation_id) => {
    const conversations = state.conversations;
    delete conversations[conversation_id];
    setState({ conversations });
  };

  /**
   * We have chat preview at the left side of every chat
   * pass the conversation id to remove specific preview
   * @param {*} conversation_id
   */
  const removeChatPreview = (conversation_id) => {
    const chats = state?.chats.filter(
      (item) => item.conversation_id === conversation_id
    );
    setState({
      chats,
    });
  };

  /**
   * Fetch Conversation messages using conversation ID
   * @param {*} conversation_id
   * @returns
   */
  const getMessagesOnlyByConversationID = (conversation_id) => {
    if (Object.keys(state.conversations).length === 0) {
      return null;
    }
    return (
      state.conversations[conversation_id ?? state.activeConversation]
        .messages || []
    );
  };

  /**
   * Set chat contacts list
   * @param {*} contacts
   * @returns
   */
  const setChatContactsList = (contacts) => {
    setState({
      contacts: [...contacts],
    });
  };

  /**
   * Retrieve single user profile by id
   * @param {*} id
   * @returns
   */
  const getChatContactListItem = (id, items = []) => {
    if (items?.length > 0) {
      return items.find((contact) => contact.id === id) || null;
    }
    return state?.contacts?.find((contact) => contact?.id === id) || null;
  };

  /**
   * Fetch chat preview
   * @returns
   */
  const getChatPreviewByConversationId = () => {
    const _foundChat = (state?.chats ?? []).find(
      (chat) => chat?.conversation_id === state?.activeConversation
    );
    const { user_deleted = false, user_blocked = false } =
      state?.conversations[state?.activeConversation]?.user_info || {};
    return { ..._foundChat, user_deleted, user_blocked };
  };

  /**
   * Push new message
   * @param {*} conversationId
   * @param {*} message
   * @returns
   */
  const pushNewMessage = (conversationId, message, callback) => {
    const conversation = state.conversations[conversationId];
    if (!conversation) {
      return;
    }
    const { messages } = conversation || {};
    if (messages?.length > 0) {
      //noticed that webhook sometimes return duplicate message
      //Todo: Verify in future
      const messageAlreadyExists = messages.find(
        (item) => item.id === message.id
      );
      if (messageAlreadyExists) return;
    }

    setState({
      conversations: {
        ...state.conversations,
        [conversationId]: {
          ...conversation,
          messages: [...conversation.messages, message],
        },
      },
    });
    setTimeout(() => {
      callback && callback();
    }, [0]);
  };

  /**
   * Check if message is sending
   * @param {*} messageSending
   */
  const setIsMessageSending = (messageSending) => {
    setState({
      messageSending,
    });
  };

  /**
   * Reload chats
   * @param {*} reloadChats
   */
  const setReloadChats = (reloadChats) => {
    setState({
      reloadChats,
    });
  };

  /**
   * Add the generated id to keep track of fresh chat
   * @param {*} id
   */
  const createFreshChatRecord = (id) => {
    setState({
      freshChat: [...state.freshChat, id],
    });
  };

  /**
   * Remove the id from fresh chats
   * @param {*} id
   */
  const removeUserFromFreshChat = (id) => {
    //remove from fresh chat array
    setState({
      freshChat: state.freshChat.filter((item) => item !== id),
    });
  };

  /**
   * In order to flush all fresh chats without handshake
   */
  const flushFreshChats = () => {
    setState({
      freshChat: [],
    });
  };

  /**
   * Check if the chat is fresh chat
   * @param {*} id
   * @returns
   */
  const isFreshChat = (id) => {
    return state.freshChat.find((item) => item === id);
  };

  const setNetworkFilters = (networkFilters) => {
    setState({
      networkFilters,
    });
  };

  /**
   * Set the group tags
   * @param {*} groupTags
   */
  const setGroupTags = (groupTags) => {
    setState({
      groupTags,
    });
  };

  /**
   * Remove group tag
   * @param {*} id
   */
  const removeGroupTag = (id) => {
    const groupTags = state.groupTags.filter((tag) => tag.id !== id);
    setState({
      groupTags,
    });
  };

  const getSuggestionList = () => {
    const suggestions = state.contacts
      ? state.contacts.filter((contact) => contact.full_name !== "")
      : [];
    return suggestions.map((suggestion) => {
      return {
        id: `${suggestion.id}`,
        text: suggestion.full_name,
      };
    });
  };

  /**
   * If group is creating
   * @param {*} status
   */
  const setIsGroupCreating = (status) => {
    setState({
      isGroupCreating: status ?? !state.isGroupCreating,
    });
  };

  const deleteChatFromState = (conversationId) => {
    removeConversationByConversationID(conversationId);
  };

  /**
   * Toggle mute/unmute status
   * @param {*} conversationId
   */
  const toggleMuteStatus = (conversationId) => {
    const chats = state.chats.map((chat) =>
      chat.conversation_id === conversationId
        ? { ...chat, mute_chat: !chat.mute_chat }
        : { ...chat }
    );
    setChats(chats);
  };

  /**
   * Set the current group id in case of editing
   * @param {*} currentGroupModalId
   */
  const setCurrentGroupModalId = (currentGroupModalId) => {
    setState({
      currentGroupModalId,
    });
  };

  /**
   * Fetch all group participants
   * @param {*} groupId
   * @returns
   */
  const fetchGroupParticipants = (groupId) => {
    const conversation = getConversationByConversationID(groupId);
    const { group_details } = conversation || {};
    if (!group_details) return [];
    return group_details?.group_user_details || [];
  };

  /**
   * Update group details
   * @param {*} conversationId
   * @param {*} details
   * @param {Array} messages
   */
  const updateGroupDetails = (conversationId, details, messages = []) => {
    const conversation = getConversationByConversationID(conversationId);
    if (conversation) {
      setState({
        conversations: {
          ...state.conversations,
          [conversationId]: {
            ...conversation,
            group_details: {
              ...details,
            },
            messages: [...(messages ?? [])],
          },
        },
      });
    }
  };

  /**
   * Determine if chat is group type
   * @param {*} conversationId
   * @returns
   */
  const isGroup = (conversationId) => {
    const preview = getChatPreviewByConversationId(
      conversationId ?? state.activeConversation
    );
    return preview && !preview?.receiver_id;
  };

  /**
   * Determine if current user is the admin of group
   * @param {*} conversationId
   * @param {*} userId
   * @returns
   */
  const isGroupAdmin = (conversationId, userId) => {
    const preview = getChatPreviewByConversationId(
      conversationId ?? state.activeConversation
    );
    if (isGroup()) {
      if (Array.isArray(preview.group_admins)) {
        return preview.group_admins.includes(userId);
      }
      return false;
    }
    return false;
  };

  /**
   * Set the current discover network filter
   * @param {*} discoverNetworkFilter
   */
  const SetDiscoverNetworkFilter = (discoverNetworkFilter) => {
    setState({
      discoverNetworkFilter,
    });
  };

  /**
   * Update status / flag if filter request is in progress
   * @param {*} isDiscoverNetworkFilterInProgress
   */
  const setIsDiscoverNetworkFilterInProgress = (
    isDiscoverNetworkFilterInProgress
  ) => {
    setState({
      isDiscoverNetworkFilterInProgress,
    });
  };
  /**
   * Set the Current Search Message Item
   * @param {*} currentSearchMessageItem
   */
  const setCurrentSearchMessageItem = (currentSearchMessageItem) => {
    setState({
      currentSearchMessageItem,
    });
  };

  /**
   * Reset the state if user is logout
   */
  const resetChatStateOnLogout = () => {
    setState({
      ...initialArgs,
    });
  };

  /**
   * Update unread messages count
   * @param {*} unreadMessagesCount
   */
  const setTotalUnreadMessagesCount = (unreadMessagesCount) => {
    setState({
      unreadMessagesCount,
    });
  };

  /**
   * Reload unread count
   * @param {*} triggerReloadUnreadCount
   */
  const setTriggerReloadUnreadCountUpdate = (triggerReloadUnreadCount) => {
    setState({
      triggerReloadUnreadCount,
    });
  };

  /**
   * Calculate total unread message count without API i.e using active chats data
   * @param {*} items
   * @returns
   */
  const calculateTotalUnreadCountWithoutAPI = (items) => {
    function evaluateCount(chats) {
      var result = chats.reduce(function (acc, obj) {
        return acc + obj.count;
      }, 0);
      return result;
    }

    if (items?.length === 0) return 0;
    if (items) {
      return evaluateCount(items);
    }

    if (!state?.chats || state?.chats?.length === 0) return 0;
    return evaluateCount(state.chats);
  };

  const setIsAttachment = (attachment) => {
    setState({
      attachment,
    });
  };

  const setFavoriteUsersCount = (favoriteUsersCount) => {
    setState({
      favoriteUsersCount,
    });
  };

  const setPreviousActiveConversation = (conversation_id) => {
    setState({
      previousActiveConversation: conversation_id,
    });
  };

  const setChatFilterTab = (filter) => {
    setState({
      chatFilterTab: filter,
    });
  };

  const setChatFilterTabAndReloadChats = (filter) => {
    setState({
      chatFilterTab: filter,
      reloadChats: true, // This will trigger the API call
    });
  };

  const updateMessageReplyData = (
    message_id = null,
    user_id = null,
    user_name = "",
    message = "",
    file_name = ""
  ) => {
    setState({
      replyChatData: {
        message_id,
        user_id,
        user_name,
        message,
        file_name,
      },
    });
  };

  return {
    isGroup,
    setChats,
    isFreshChat,
    reorderChats,
    setGroupTags,
    isGroupAdmin,
    setIsAttachment,
    setReloadChats,
    pushNewMessage,
    removeGroupTag,
    flushFreshChats,
    getChatsIdsOnly,
    toggleMuteStatus,
    setIsChatsLoaded,
    removeChatPreview,
    setIsChatsLoading,
    getSuggestionList,
    setNetworkFilters,
    setIsGroupCreating,
    updateGroupDetails,
    updateLatestMessage,
    setIsMessageSending,
    setChatContactsList,
    deleteChatFromState,
    createFreshChatRecord,
    updateChatsUnreadCount,
    resetChatStateOnLogout,
    getChatContactListItem,
    setCurrentGroupModalId,
    fetchGroupParticipants,
    removeUserFromFreshChat,
    setCurrentConversationId,
    SetDiscoverNetworkFilter,
    setConversationInformation,
    getChatPreviewByReceiverId,
    setCurrentSearchMessageItem,
    setTotalUnreadMessagesCount,
    updateConversationInformation,
    getChatPreviewByConversationId,
    getConversationByConversationID,
    getMessagesOnlyByConversationID,
    setTriggerReloadUnreadCountUpdate,
    removeConversationByConversationID,
    calculateTotalUnreadCountWithoutAPI,
    setIsDiscoverNetworkFilterInProgress,
    setGroupCreateRequestData,
    setSocketConnectionStatus,
    setFavoriteUsersCount,
    setPreviousActiveConversation,
    setChatFilterTab,
    setChatFilterTabAndReloadChats,
    setConversationChannelBroadcast,
    setSocketReactionsConnectionStatus,
    setConversationReactionsChannelBroadcast,
    updateMessageReplyData,
    ...state,
  };
};

export default useChat;
